import React, { useRef, useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import "./Map.css";

const Map = ({ stores, selectedStoreId, onMarkerClick, coordinates }) => {
  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [geoJsonVisible, setGeoJsonVisible] = useState(true);
  const [geoJsonDisabled, setGeoJsonDisabled] = useState(false);

  const mapContainerStyle = {
    height: "100vh",
  };

  const defaultCenter = {
    lat: 49.0384,
    lng: 31.4513,
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    // styles: [
    //   {
    //     featureType: "administrative",
    //     elementType: "geometry",
    //     stylers: [{ visibility: "off" }],
    //   },
    // ],
  };

  const selectedStore = stores.find((store) => store.id === selectedStoreId);

  const center = coordinates
    ? { lat: coordinates.lat, lng: coordinates.lng }
    : selectedStore
    ? { lat: selectedStore.latitude, lng: selectedStore.longitude }
    : defaultCenter;

  useEffect(() => {
    if (mapRef.current && selectedStore) {
      const newCenter = {
        lat: selectedStore.latitude,
        lng: selectedStore.longitude,
      };
      setMapCenter(newCenter);
      setZoom(12);
      mapRef.current.panTo(newCenter);
      setGeoJsonVisible(false);
      setGeoJsonDisabled(true);
    }
  }, [selectedStore]);

  useEffect(() => {
    if (mapRef.current && coordinates) {
      setMapCenter(coordinates);
      setZoom(12);
      mapRef.current.panTo(coordinates);
      setGeoJsonVisible(false);
      setGeoJsonDisabled(true);
    }
  }, [coordinates]);

  useEffect(() => {
    if ((!coordinates && !selectedStore) || center === defaultCenter) {
      if (!geoJsonDisabled) {
        setGeoJsonVisible(true);
      }
    } else {
      setGeoJsonVisible(false);
      setGeoJsonDisabled(true);
    }
  }, [coordinates, selectedStore, center, defaultCenter, geoJsonDisabled]);

  useEffect(() => {
    if (mapRef.current) {
      if (geoJsonVisible && !geoJsonDisabled) {
        try {
          mapRef.current.data.loadGeoJson('/geoBoundaries-UKR-ADM0_simplified.geojson');
          mapRef.current.data.setStyle({
            fillColor: 'green',
            fillOpacity: 0,
            strokeWeight: 3,
            strokeColor: '#2189FF',
          });
        } catch (error) {
          console.error('Error in loading GeoJSON:', error);
        }
      } else {
        mapRef.current.data.forEach((feature) => {
          mapRef.current.data.remove(feature);
        });
      }
    }
  }, [geoJsonVisible, geoJsonDisabled]);

  const handleMapLoad = (map) => {
    mapRef.current = map;
    if (geoJsonVisible && !geoJsonDisabled) {
      try {
        map.data.loadGeoJson('/geoBoundaries-UKR-ADM0_simplified.geojson');

        map.data.addListener('addfeature', (event) => {
          console.log('Feature added:', event.feature);
        });

        map.data.addListener('error', (error) => {
          console.error('Error loading GeoJSON:', error);
        });

        map.data.setStyle({
          fillColor: 'green', // Change this to the desired fill color or keep it the same if you want to just adjust opacity
          fillOpacity: 0, // This removes the internal color by making it fully transparent
          strokeWeight: 3,
          strokeColor: '#2189FF',
        });
      } catch (error) {
        console.error('Error in handleMapLoad:', error);
      }
    }
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
      setGeoJsonVisible(false);
      setGeoJsonDisabled(true);
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={mapCenter || center}
      zoom={zoom}
      options={mapOptions}
      onLoad={handleMapLoad}
      onZoomChanged={handleZoomChanged}
    >
      {stores.map((store) => (
        <Marker
          key={store.id}
          position={{ lat: store.latitude, lng: store.longitude }}
          onClick={() => onMarkerClick(store.id)}
        >
          {selectedStoreId === store.id && (
            <InfoWindow
              position={{ lat: store.latitude, lng: store.longitude }}
              onCloseClick={() => onMarkerClick(null)}
            >
              <div>
                <h3>{store.name}</h3>
                <p className="centered-content">
                  <img src="/icons/Icon 1-1.svg" alt="tel" className="icon-small right" />
                  {store.address}
                </p>
                <p className="centered-content">
                  <img src="/icons/Call Regular.svg" alt="tel" className="icon-small right" />
                  <a className="notmain" href={`tel:${store.tel}`}>{store.tel}</a>
                </p>
                {store.website !== "www.example.com" && (
                  <p className="centered-content">
                    <img src="/icons/Icon 6-6.svg" alt="email" className="icon-small right" />
                    <a className="main" href={`http://${store.website}`} target="_blank" rel="noopener noreferrer">
                      {store.website}
                    </a>
                  </p>
                )}
                {store.email !== "default@example.com" && (
                  <p className="centered-content">
                    <img src="/icons/Email Regular.svg" alt="email" className="icon-small right" />
                    <a className="notmain" href={`mailto:${store.email}`}>{store.email}</a>
                  </p>
                )}
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
};

export default Map;
