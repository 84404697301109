import React, { useState, useEffect } from 'react';

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(true);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
    setIsVisible(false); // Hide the button after it is clicked
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop === 0) {
      setIsVisible(true); // Show the button when scrolled to the top
    } else if (scrollTop + windowHeight >= documentHeight) {
      setIsVisible(false); // Hide the button when scrolled to the bottom
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <button className="scroll-button" onClick={scrollToBottom}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          className="icon-md m-1 text-token-text-primary"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 21a1 1 0 0 1-.707-.293l-7-7a1 1 0 1 1 1.414-1.414L11 17.586V4a1 1 0 1 1 2 0v13.586l5.293-5.293a1 1 0 0 1 1.414 1.414l-7 7A1 1 0 0 1 12 21"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    )
  );
};

export default ScrollButton;
