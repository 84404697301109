import React, { useState, useEffect } from "react";
import "./App.css";
import Map from "./Map";
import StoreList from "./StoreList";
import { useJsApiLoader } from "@react-google-maps/api";
import "./StoresLocator.css";

const libraries = ["places"];

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDtnIEFxpvvoBUoQc0DPMOV-szkII7szaI",
    libraries,
    version: "beta",
  });

  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState(stores);
  const [coordinates, setCoordinates] = useState(null);  // Add this line

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await fetch("https://api.samsung-partner-locator.com.ua/stores/");
        if (response.ok) {
          const data = await response.json();
          setStores(data.stores);
        } else {
          console.error("Failed to fetch stores");
        }
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchStores();
  }, []);

  const handleStoreClick = (storeId) => {
    setSelectedStoreId(storeId);
  };

  const updateFilteredStores = (filteredStores) => {
    setFilteredStores(filteredStores);
  };

  const updateCoordinates = (newCoordinates) => {  // Add this function
    setCoordinates(newCoordinates);
  };

  return isLoaded ? (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="store-list">
            <StoreList
              stores={stores}
              onStoreClick={handleStoreClick}
              updateFilteredStores={updateFilteredStores}
              updateCoordinates={updateCoordinates}  // Pass this function
            />
          </div>
          <div className="map">
            <Map
              stores={filteredStores}
              selectedStoreId={selectedStoreId}
              onMarkerClick={handleStoreClick}
              coordinates={coordinates}  // Pass coordinates here
            />
          </div>
        </div>
      </header>
    </div>
  ) : (
    <></>
  );
}

export default App;