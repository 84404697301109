import React, { useState, useRef, useEffect } from "react";
import Filters from "./Filters";
import StoreSelection from "./StoreSelection";
import ScrollButton from "./ScrollButton";
import "./StoresLocator.css";

const StyledComponent = () => {
  return (
    <div className="styled-border">
    </div>
  );
};

const StoreList = ({ stores, onStoreClick, updateFilteredStores, updateCoordinates }) => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [filteredStores, setFilteredStores] = useState(stores);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({
    smartthings: false,
    "smartthings-accessories": false,
    "air-conditioners": false,
    "system-air-conditioners": false,
  });
  const [selectedDistance, setSelectedDistance] = useState(30); // Default distance
  const [expanded, setExpanded] = useState(false);
  const [mobile, setMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const [selectedStoreId, setSelectedStoreId] = useState(null); // Track selected store

  const inputRef = useRef(null);

  useEffect(() => {
    setFilteredStores(stores);
  }, [stores]);

  useEffect(() => {
    updateFilteredStores(filteredStores);
  }, [filteredStores, updateFilteredStores]);

  useEffect(() => {
    if (coordinates) {
      filterStoresByDistance(coordinates);
    } else {
      filterStoresByFilters();
    }
  }, [coordinates, filters, selectedDistance]);

  const handlePlaceSelect = (place) => {
    if (place.geometry) {
      setAddress(place.formatted_address || "");
      const location = place.geometry.location;
      if (location) {
        const locationCoords = location.toJSON();
        setCoordinates(locationCoords);
        updateCoordinates(locationCoords);
        filterStoresByDistance(locationCoords);
      } else {
        setCoordinates(null);
        updateCoordinates(null);
        setFilteredStores(stores);
      }
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setAddress("Моя поточна локація");
            setCoordinates(location);
            updateCoordinates(location);
            filterStoresByDistance(location);
          },
          (error) => {
            console.error("Error getting the current location:", error);
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const filterStoresByFilters = (storesToFilter = stores) => {
    if (Object.values(filters).every((value) => !value)) {
      setFilteredStores(storesToFilter);
      return;
    }

    const filtered = storesToFilter.filter((store) => {
      const categoryMatchesFilter = store.categories.some((category) => {
        return filters[category];
      });

      return categoryMatchesFilter;
    });

    setFilteredStores(filtered);
  };

  const filterStoresByDistance = (coords) => {
    const filtered = stores.filter((store) => {
      const distance = calculateDistance(
        coords.lat,
        coords.lng,
        store.latitude,
        store.longitude
      );
      return distance <= selectedDistance;
    });
    filterStoresByFilters(filtered);
  };

  const handleDistanceChange = (distance) => {
    setSelectedDistance(distance);
  };

  const showModalHandler = () => {
    setShowModal(!showModal);
  };
  const hideModalHandler = () => setShowModal(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleStoreClick = (storeId) => {
    onStoreClick(storeId);
    setSelectedStoreId(storeId); // Set selected store
    if (mobile) {
      setExpanded(!expanded);
    }
  };

  // Determine the class to apply based on the state
  const containerClass = `${expanded && mobile ? 'expanded-mobile' : ''} ${!mobile ? 'expanded-desktop' : ''}`;
  const modalClass = showModal ? 'show' : '';

  return (
    <div className="store-list-container-wrapper">
      <StoreSelection
        address={address}
        setAddress={setAddress}
        handlePlaceSelect={handlePlaceSelect}
        inputRef={inputRef}
        setCoordinates={setCoordinates}
        setFilteredStores={setFilteredStores}
        stores={stores}
      />

      <div>
        <div className="store-list-container">
          <div className="header-row-container">
            <div>
              <p>
                {filteredStores.length === 0
                  ? "Немає доступних партнерів"
                  : `Перелік усіх ${filteredStores.length} партнерів`}
              </p>
            </div>
            <div className="expand-toggle">
              <button
                className="button-expand-outline-show"
                onClick={toggleExpand}
              >
                {expanded ? "Сховати" : "Показати"}
              </button>
            </div>
            <ScrollButton></ScrollButton>     
            <div className="button-container-input vertical-align">
              <img
                src={showModal ? "/icons/Icon 5.svg" : "/icons/Icon 4.svg"}
                alt="address"
                className={`icon ${showModal ? "active" : ""}`}
                onClick={showModalHandler}
              />
            </div>
          </div>

          <div className={`modal-container button-container ${modalClass}`}>
            <div className="modal-content">
              <Filters
                handleClose={hideModalHandler}
                filters={filters}
                setFilters={setFilters}
                selectedDistance={selectedDistance}
                handleDistanceChange={handleDistanceChange}
              />
            </div>
            <StyledComponent></StyledComponent>

          </div>

          <div className={`store-list-data-container ${containerClass}`}>
            {filteredStores.length === 0 ? (
              <div className="store-item-empty"></div>
            ) : (
              filteredStores.map((store) => (
                <div
                  className={`store-item ${store.id === selectedStoreId ? 'selected' : ''}`} // Apply selected class
                  key={store.id}
                >
                  <div className="store-item-left"></div>
                  <div onClick={() => handleStoreClick(store.id)}>
                    <p>
                      <b>{store.name}</b>
                    </p>
                    <p>{store.address}</p>
                    <p>
                      <a className="notmain" href={`tel:${store.tel}`}>
                        {store.tel}
                      </a>
                    </p>
                    {store.website !== "www.example.com" && (
                      <p>
                        <a
                          className="main"
                          href={`http://${store.website}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {store.website}
                        </a>
                      </p>
                    )}
                    {store.email !== "default@example.com" && (
                      <p>
                        <a className="notmain" href={`mailto:${store.email}`}>
                          {store.email}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              ))
            )}       
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreList;
