import React from "react";
import { Autocomplete } from "@react-google-maps/api";

const StoreSelection = ({
  address,
  setAddress,
  handlePlaceSelect,
  inputRef,
  setCoordinates,
  setFilteredStores,
  stores,
}) => {
  return (
    <div className="header-container">
      <div className="header">
        <h2>Обрати партнера</h2>
      </div>

      <div className="row">
        <div className="row-container">
          <div className="autocomplete-container">
            <div className="input-container">
              <Autocomplete
                onLoad={(autocomplete) => {
                  autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    handlePlaceSelect(place);
                  });
                }}
                options={{
                  componentRestrictions: { country: "ua" },
                  fields: ["formatted_address", "geometry"],
                  types: ["(cities)"],
                }}
              >
                <input
                  ref={inputRef}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Введіть місце розташування"
                  className="autocomplete-input"
                  type="text"
                />
              </Autocomplete>
            </div>
          </div>

          {address && (
            <div className="button-container-input">
              <img
                src="/icons/Icon 2.svg"
                alt="address"
                className="icon"
                onClick={() => {
                  setAddress("");
                  setCoordinates(null);
                  setFilteredStores(stores);
                }}
              />
            </div>
          )}
          {!address && (
            <div className="button-container-input">
              <img
                src="/icons/Vector-6.svg"
                alt="address"
                className="icon"
                onClick={() => handlePlaceSelect({})}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreSelection;
