// Filters.js
import React from "react";
import "./StoresLocator.css";

const Filters = ({
  handleClose,
  filters,
  setFilters,
  selectedDistance,
  handleDistanceChange,
}) => {
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const resetFilters = () => {
    setFilters({
      smartthings: false,
      "smartthings-accessories": false,
      "air-conditioners": false,
      "system-air-conditioners": false,
    });
    handleDistanceChange(30);
  };

  return (
    <div className="filters-container">
      <p>
        <b>Відстань, км</b>
      </p>
      <div className="distance-buttons">
        {[10, 20, 30, 40, 50].map((distance) => (
          <button
            key={distance}
            className={`distance-button ${
              selectedDistance === distance ? "selected" : ""
            }`}
            onClick={() => handleDistanceChange(distance)}
          >
            {distance}
          </button>
        ))}
      </div>
      <p>
        <b>Фільтри</b>
      </p>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            className="round-checkbox"
            name="smartthings"
            checked={filters.smartthings}
            onChange={handleCheckboxChange}
          />
          Smartthings
        </label>
        <label>
          <input
            type="checkbox"
            className="round-checkbox"
            name="smartthings-accessories"
            checked={filters["smartthings-accessories"]}
            onChange={handleCheckboxChange}
          />
          Smartthings Accessories
        </label>
        <label>
          <input
            type="checkbox"
            className="round-checkbox"
            name="air-conditioners"
            checked={filters["air-conditioners"]}
            onChange={handleCheckboxChange}
          />
          Кондиціонери
        </label>
        <label>
          <input
            type="checkbox"
            className="round-checkbox"
            name="system-air-conditioners"
            checked={filters["system-air-conditioners"]}
            onChange={handleCheckboxChange}
          />
          System Air Conditioners
        </label>
      </div><br/>
      <section className="modal-main">
        <button
          className="button-rounded-outline"
          onClick={resetFilters}
        >
          Скинути
        </button>
        <button className="button-rounded-black" onClick={handleClose}>
          Закрити
        </button>
      </section>
    </div>
  );
};

export default Filters;
